.btn-centered-eye{
  padding: 3px 7px;
}

.btn-centered-times{
  padding: 3px 9px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

small{
  color: red;
}