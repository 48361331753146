// Import All Plugins assets...
@import "~bootstrap/scss/bootstrap"; // Bootstrap 4 css
/*@import "../css/bootstrap.min.css"; // Custom Bootstrap 4 css*/
@import "~animate.css/animate.min.css"; // Animate css
@import "~@fortawesome/fontawesome-free/css/all.min.css"; // Font awesome 5 css

@import "~owl.carousel/dist/assets/owl.carousel.css";

// Import All css assets...
/*@import "../css/style.css";*/
